import { graphql } from 'gatsby'
import get from 'lodash/get'
import Img from 'gatsby-image'
import React from 'react'

import '../styles/member.scss'
import { siteMetadata } from '../../gatsby-config'
import Layout from 'components/layout'
import Meta from 'components/meta'
// import Icon from 'components/icon'

class Member extends React.Component {
  render() {
    const { location, data } = this.props
    const gamerinshaft = get(data, 'gamerinshaft.childImageSharp.fixed')
    const hiroyoshi = get(data, 'hiroyoshi.childImageSharp.fixed')
    const kaoru = get(data, 'kaoru.childImageSharp.fixed')
    const hangai = get(data, 'hangai.childImageSharp.fixed')
    const katayori = get(data, 'katayori.childImageSharp.fixed')
    const takata = get(data, 'takata.childImageSharp.fixed')
    const yasunaga = get(data, 'yasunaga.childImageSharp.fixed')
    const monta = get(data, 'monta.childImageSharp.fixed')
    const morita = get(data, 'morita.childImageSharp.fixed')
    const kota = get(data, 'kota.childImageSharp.fixed')
    const akane = get(data, 'akane.childImageSharp.fixed')
    const jokura = get(data, 'jokura.childImageSharp.fixed')
    const watanabe = get(data, 'watanabe.childImageSharp.fixed')
    const yoshihara = get(data, 'yoshihara.childImageSharp.fixed')
    const kayama = get(data, 'kayama.childImageSharp.fixed')
    const miharun = get(data, 'miharun.childImageSharp.fixed')
    const onishi = get(data, 'onishi.childImageSharp.fixed')
    const akie = get(data, 'akie.childImageSharp.fixed')
    const kaito = get(data, 'kaito.childImageSharp.fixed')
    const anzai = get(data, 'anzai.childImageSharp.fixed')
    const ono = get(data, 'ono.childImageSharp.fixed')
    const nozue = get(data, 'nozue.childImageSharp.fixed')

    const members = [
      {
        id: 'gamerinshaft',
        name: '種市 隼兵',
        image: gamerinshaft,
        role: '代表取締役 CEO',
        description:
          '1993年生まれ、東京理科大学理工学部情報科学科卒。3年次プロダクトは「スマホから家電を操作するIRシステムの開発」、4年次の研究は「FTM(3入力3出力可逆論理)ゲートの最適配置アルゴリズムの研究」。入学当初よりエンジニアとして働く一方で、大手リクルート会社と共同で旧帝大新卒を対象にしたブートキャンプのテクニカルコーチを務める。これらの経験をもとに、学内システムの改善を目的としたエンジニアギルドを組成。クリエーターの育成基盤を整え、タレントシェアリングのエコシステムを構築。マサチューセッツ工科大学が提供している経済発展を目指した教育プログラム、MIT REAP@東京理科大学のモデルケースとなり、ハッカソン・ビジコンでの優勝をきっかけに東京理科大学ベンチャーキャピタルファンドから出資を受け学部４年時に株式会社Shinonomeを創業。今に至る。',
      },
      {
        id: 't-hiroyoshi',
        name: '髙橋 弘至',
        image: hiroyoshi,
        role: 'Co-founder & 取締役CTO',
        description:
          '1993年生まれ、東京理科大学基礎工学部生物工学科卒。 株式会社Shinonome Co-founder&取締役CTO, 東京理科大学O.C. 講師。 モットーは"人類の進歩、進化を加速させていく"。 学生時代にクラフトマンソフトウェアにソフトウェアエンジニアとして参画。 復学後、Scala NativeなどのOSS開発に参加し、世界中のエンジニアと交流を進める。 CTOとしてテクノロジーを用いて価値の最大化を目指す。',
      },
      {
        id: 'monta',
        name: '井戸 聞多',
        image: monta,
        role: 'Project Manager',
        description:
          '富士ゼロックス株式会社にて複合機開発、新卒採用人事に携わったのち、新規事業開発部門でデバイス開発を担当。専門は機械工学、制御だが実務の中で基板、通信、データ解析などハードウェア商品開発に必要な技術を一通り学ぶ。採用人事経験、およびラグビークラブで日本一8回の経験の中でチームビルディングや教育哲学を独自で構築。2019年4月に長野県山ノ内町に移住し志賀高原のガイドサービスの会社である合同会社MOUNTAIN DISCOVERYを共同創業、Shinonomeではシステム開発のプロジェクトマネージャーと組織開発に関わり、新規事業として地方創生部門を担当。志賀高原内のコワーキングスペース「hiroen」を運営してリゾートテレワークというライフスタイルを発信している。',
      },
      {
        id: 'onishi',
        name: '大西 信慈',
        image: onishi,
        role: 'Executive Manager',
        description:
          '大学卒業後、大手メーカーで携帯電話のソフトウェア開発に携わり、要件定義～開発～テスト、プロジェクトマネジメントを担当。その後、企画系部門にて、商品企画、事業戦略策定、新規事業開発、海外拠点開拓に従事。また、知財と事業・研究を結びつけるべくICT・DX領域の知財アナリストとしても活動。Shinonomeの理念、ビジョンに共感しジョイン。戦略立案や事業開発を主に担当。早稲田大学大学院商学研究科ビジネス専攻（MBA）修了。',
      },
      {
        id: 'mcz9mm',
        name: '又来 薫',
        image: kaoru,
        role: 'iOS / Android Developer',
        description:
          '1994年生まれ、東京理科大学経営学部経営学科卒。大学時代に独学でiOSアプリを開発・リリースを経験。 経営学を専攻し、画像認識技術を用いて工場の生産ラインの研究を行う。 2017年新卒入社し、不動産系アプリのiOS開発を大規模リニューアルやiOS11の新機能対応を経験。 2019年1月にShinonomeにジョイン。iOS、Androidアプリを担当。 iOSDCでの登壇や個人で仮想通貨系アプリの作成もしている。',
      },
      {
        id: 'yoshihara',
        name: '吉原 佑輝',
        image: yoshihara,
        role: 'Back-End / Front-End Developer',
        description:
          '大学入学後1ヶ月でWebアプリケーション開発を行うベンチャーでインターンを開始、大学在籍中に複数の会社でWebアプリケーション開発を経験し、大学3年次で中退。2017年に株式会社LIFULLに新卒入社。不動産ポータルサイトの基盤開発に携わる。2020年に株式会社Shinonomeにジョイン。Webアプリの開発を担当。PlayGround諏訪拠点の管理人。',
      },
      {
        id: 'shirai',
        name: '白井 康太',
        image: kota,
        role: 'Business Development',
        description:
          '大学2年時よりスタートアップで営業を経験し、その後4000人規模の学生イベントを主催。新卒で株式会社ネオキャリアに入社後、保育士の転職支援を行う営業職(toB,toC)として勤務し、事業部最速でリーダーに昇格。その後、プログラミングスクールを運営する株式会社インフラトップ(合同会社DMM.comグループ)に入社、人材紹介事業の立ち上げを行う。立ち上げから2年で年間売上1億円、組織は東西合わせて14名となり事業部長(マネージャー)として事業戦略・KPI設定・事業部KPI達成の為の戦略立案、組織マネジメント、採用教育など行う。Shinonomeの理念、ビジョンに共感しジョイン。現在に至る。',
      },
      {
        id: 'akane',
        name: '齋藤 茜',
        image: akane,
        role: 'Community Manager',
        description:
          '1996年生まれ、外国語学部英語学科卒。大学卒業後、留学者の受け入れ元である語学学校の運営に携わる。クライアントとの渉外対応、海外の学校にて生徒サポートを行う。学びと仕事を両立し、学生がより多くの分野で輝くことが出来るきっかけ作りを学内で提供しているShinonomeの活動に感銘を受け、2019年11月にジョイン。学生と共に変化し前進し続けているこのコミュニティを支え、またその魅力を、様々な人に知ってもらい「新たな学びの場」の価値に気づいて貰えるきっかけ作りを行う。',
      },
      {
        id: 'watanabe',
        name: '渡辺 快',
        image: watanabe,
        role: 'Back Office & 経理',
        description:
          '1997年生まれ、早稲田大学商学部卒。学生起業で合同会社Back to Frontを設立し、「バックオフィスをより簡単に、より自然に」をモットーに、会計・人事労務を中心としたバックオフィス業務の効率化を支援している。経理システム等のクラウド化、クラウド導入に強み。freee5つ星認定アドバイザー。',
      },
      {
        id: 'miharun',
        name: '文野 栄樹',
        image: miharun,
        role: 'Back-End Developer',
        description:
          '東京理科大学理学部第一部数学科卒。大学在籍中に株式会社Shinonomeが運営するコミュニティPlayGroundに所属。大学2年時に企業との共同研究開発を経験したことをきっかけに複数の開発案件に携わる。2021年にShinonomeに新卒入社後、PlayGround岡山拠点の管理人として活動。Webアプリ開発を担当。',
      },
      {
        id: 'akie',
        name: '劉 明惠',
        image: akie,
        role: 'Designer',
        description:
          '1996年生まれ、千葉大学工学部デザイン学科卒。在学中はUXデザインを中心とした産学連携や大陸間デザインWSに参加。新卒でダイキン工業株式会社に入社後、技術職として商品開発に携わり、特許アイディアの考案等行った。学びの場を提供し、学生と共に事業を作り上げ、共に成長するShinonomeのあり方に強く惹かれる。デザイナーとしてWeb制作・運営の双方を盛り上げたいと考え、2021年9月にジョイン。',
      },
      {
        id: 'morita',
        name: '森田 善乃',
        image: morita,
        role: 'プロジェクトマネージャー',
        description:
          '2015年早稲田大学社会科学部卒業後、株式会社アサツーディ・ケイに入社。メディア部門で営業に従事する傍ら、NTT西日本やGMOクラウド主催のハッカソンにて受賞。エンジニアの勉強を経て、2017年に寺田倉庫株式会社へ入社。Webアプリケーション実装の経験の後、物流プラットフォームシステムのPMや他社へ物流導入の立ち上げを複数経験。2019年にTrysmを設立し、企業の新規事業の立ち上げや業務効率化のサポートを行う。',
      },
      {
        id: 'nozue',
        name: '野末 愛子',
        image: nozue,
        role: 'プロジェクトマネージャー',
        description:
          '大学卒業後、複合機メーカーにて、業務システムをメインに様々な開発プロジェクトを経験。その後車載メーカーにて、スマート農業やトレーサビリティに関する新規事業開発、ソフトウェア開発に携わる。2022年より、Shinonomeの理念、ビジョンに共感しジョイン。',
      },
      {
        id: 'anzai',
        name: '安西 翔平',
        image: anzai,
        role: '広島コミュニティマネージャー',
        description:
          '2016年に東京農業大学を中退、ドイツに留学し難民にプログラミングを教えるボランティアに従事。帰国後にNPO法人WELgee、株式会社Blockchain Technologies、株式会社ハッシャダイにてプログラミング学習・キャリア支援事業に携わる。2020年に合同会社GeneLeafを設立し、広島県江田島市にて受託開発や教育事業、地元業者のDX支援等を行う。 ',
      },
      {
        id: 'ono',
        name: '小野 博',
        image: ono,
        role: 'マーケティング',
        description:
          '2015年順天堂大学大学院修了後、健康食品・美容機器通販およびパーソナルトレーニングジム運営会社に入社。店舗来店型事業のマーケティングに従事。主にオンライン、オフラインを統合したメディアプランの策定と実行を推進。2020年よりパラレルワーカーとして一般社団法人ワークデザインラボにパートナーとして参画。2022年1月に合同会社Meditation Marketingを設立。',
      },
      {
        id: 'kaito',
        name: '荒井 海斗',
        image: kaito,
        role: 'Intern',
        description:
          '2002年生まれ、早稲田大学社会科学部在学中。大学3年次での休学をきっかけにShinonomeでインターンを開始。PlayGroundでは、Mobileコースの運営と、一昨年に発足したWILL(Waseda IT Leader Lab)の運営を行う。',
      },
    ]

    const exMembers = [
      {
        id: 'takata',
        name: '高田 久徳',
        image: takata,
        role:
          '東京理科大学イノベーション・キャピタル株式会社 代表取締役 マネージング・パートナー',
        description:
          '大学卒業後、第一生命保険相互会社(現第一生命保険株式会社)に入社。株式会社みずほコーポレート銀行（現株式会社みずほ銀行）及びMountain Capital Advisors（現The Carlyle Group）に出向し、M&Aファイナンス、プライベート・エクイティ投資及びクレジット投資業務を担当。その後、第一生命保険、農林中央金庫においてベンチャー投資、プライベート・エクイティ投資及びクレジット投資業務に従事。2015年に株式会社TUSビジネスホールディングス（現東京理科大学インベストメント・マネジメント株式会社）に参画し、取締役に就任。2016年、学校法人東京理科大学シニア・ポートフォリオ・マネジャー兼理事補佐（財務担当）、2018年に東京理科大学イノベーション・キャピタル株式会社代表取締役に就任。慶應義塾大学経済学部卒、東京理科大学大学院経営学研究科技術経営修士課程在籍。',
      },
      {
        id: 'hangai',
        name: '半谷 精一郎',
        image: hangai,
        role: '東京理科大学 名誉教授',
        description:
          '東京理科大学工学部名誉教授。元米国スタンフォード大学CSLI客員研究員。専門は、音声、画像、生体認証のための信号処理著書に、ディジタル信号処理、JPEG/MPEG完全理解、コンピュータ概論、バイオメトリクス教科書などがある。',
      },
    ]

    const partners = [
      {
        id: 'katayori',
        name: '片寄 裕市',
        image: katayori,
        role: '経営顧問',
        description:
          '大学院修了後、第一生命保険相互会社（現第一生命保険株式会社）に入社。資産運用部門にてオルタナティブ投資およびストラクチャード・ファイナンス関連の投資業務に携わる。米国現地法人および海外資産運用会社への出向等を経て、ゴールドマン・サックス・アセット・マネジメント株式会社に入社。2015年、学校法人東京理科大学の大学関連事業とベンチャー企業支援を担う株式会社TUSビジネスホールディングス（現東京理科大学インベストメント・マネジメント株式会社）に参画、代表取締役就任。2016年、学校法人東京理科大学理事長特命補佐、東京理科大学研究推進機構客員准教授、2018年、東京理科大学イノベーション・キャピタル株式会社を設立し代表取締役に就任。東京理科大学経営学部卒、東京理科大学大学院経営学研究科修士課程修了。',
      },
      {
        id: 'kayama',
        name: '香山 哲',
        image: kayama,
        role: 'アドバイザー',
        description:
          'コムウェア株式会社代表取締役社長、株式会社ライフクエスト代表取締役、ジェネシスヘルスケア取締役など現在も多数の会社の経営に携わる。株式会社セガ代表取締役、トムスエンタテインメント取締役、円谷プロダクション取締役、ソニー・コンピュータエンタテインメントコーポレートフェロー、トランスコスモス特別顧問、エムティーアイ特別顧問などIT、エンタテインメント業界での豊富な経験を活かした活動を行なっている。',
      },
      {
        id: 'yasunaga',
        name: '安永 達幸',
        image: yasunaga,
        role: 'アソシエイター',
        description:
          'IT戦略ラボ 代表。理窓ワトソン会会長。元日本IBM執行役員,元ソフトバンクテレコム執行役員, 元サンガードシステムズジャパン社長',
      },
      {
        id: 'jokura',
        name: '城倉 和孝',
        image: jokura,
        role: 'リスクマネジメント・戦略相談',
        description:
          '株式会社ソフトクリエイトにて、多岐にわたる業務システムのPM、Si開発業務に従事。並行して開発したワークフローシステムで事業をSiからパッケージへとシフトして、2007年に株式会社エイトレッドとして分社化。取締役兼CTOとしてX-point、AgileWorksなどプロダクトの企画、開発を行う。        2011年に株式会社DMM.comラボにジョインし取締役兼CTO、2018年から合同会社DMM.com 執行役員CTO/CIOとしてエンジニア組織構築と事業の拡大に従事。        2018年にバイナリー合同会社を創業し、複数社のエンジニア組織構築およびIT全般に係る支援を行っている。',
      },
    ]

    const benefactors = [
      {
        id: 'makino',
        name: '牧野 恵美',
        role:
          '学術・社会連携室産学・地域連携推進部アントレプレナー教育部門アントレプレナー教育部門准教授',
      },
      {
        id: 'matsuda',
        name: '松田 大',
        role: '東京理科大学情報システム課課長',
      },
      {
        id: 'obata',
        name: '小幡 健太郎',
        role: 'アストマックス株式会社 常務取締役',
      },
      {
        id: 'taniguchi',
        name: '谷口 幸四郎',
        role: 'アストマックス株式会社 顧問',
      },
      {
        id: 'sugiatsu',
        name: '杉本 篤彦',
        role: '株式会社BackCast 代表取締役',
      },
    ]

    // const nameStyle = {
    //   fontSize: '1.2rem',
    //   verticalAlign: 'middle',
    // }
    // const roleStyle = {
    //   fontSize: '1.2rem',
    //   borderLeft: '2px solid #666',
    //   marginLeft: '12px',
    //   paddingLeft: '12px',
    //   verticalAlign: 'middle',
    //   lineHeight: '28px',
    //   marginBottom: '0px',
    // }

    // const titleStyle = {
    //   fontSize: '32px',
    //   color: '#333',
    //   letterSpacing: '4px',
    //   position: 'relative',
    //   zIndex: 3,
    // }

    // const titleShadowStyle = {
    //   fontSize: '48px',
    //   color: '#f3f3f3',
    //   letterSpacing: '4px',
    //   position: 'absolute',
    //   left: 32,
    //   bottom: 10,
    //   fontWeight: 700,
    // }

    const renderMainList = (objects, c) =>
      objects.map(o => (
        <div
          className={`media mb-5 ${c} d-sm-flex d-block text-sm-left text-center`}
          key={o.id}
        >
          <Img fixed={o.image} />
          <div className="media-body ml-3">
            <div className="mb-1">
              <h2 className="name d-inline-block mb-0">{o.name}</h2>
              <h3 className="role d-inline-block">{o.role}</h3>
            </div>
            <p className="description">{o.description}</p>
          </div>
        </div>
      ))

    const renderSubList = (objects, c) =>
      objects.map(o => (
        <li key={o.id} className="list-group-item">
          <strong className="mr-3">{o.name}</strong>-
          <span className="ml-3">{o.role}</span>
        </li>
      ))

    return (
      <Layout location={location}>
        <Meta site={siteMetadata} title="Member" />
        <main className="member mt-5">
          <h1>
            <span className="title">メンバー</span>
            <span className="title-shadow">MEMBER</span>
          </h1>
          <section className="pt-4">
            <div className="row">{renderMainList(members, 'col-12')}</div>
          </section>
          <h1>
            <span className="title">社外取締役</span>
            <span className="title-shadow">Ex Officer</span>
          </h1>
          <section className="pt-4">
            <div className="row">{renderMainList(exMembers, 'col-12')}</div>
          </section>
          <h1>
            <span className="title">パートナー</span>
            <span className="title-shadow">Partner</span>
          </h1>
          <section className="pt-4">
            <div className="row">
              {renderMainList(partners, 'col-12 col-xl-6')}
            </div>
          </section>
          <h1>
            <span className="title">フェロー</span>
            <span className="title-shadow">Benefactor</span>
          </h1>
          <section className="pt-4">
            <ul className="list-group list-group-flush">
              {renderSubList(benefactors)}
            </ul>
          </section>
        </main>
      </Layout>
    )
  }
}

export default Member

export const query = graphql`
  query MemberPageQuery {
    gamerinshaft: file(name: { eq: "gamerinshaft" }) {
      childImageSharp {
        fixed(width: 100, height: 100) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    hiroyoshi: file(name: { eq: "hiroyoshi" }) {
      childImageSharp {
        fixed(width: 100, height: 100) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    kaoru: file(name: { eq: "kaoru" }) {
      childImageSharp {
        fixed(width: 100, height: 100) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    kota: file(name: { eq: "kota" }) {
      childImageSharp {
        fixed(width: 100, height: 100) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    akane: file(name: { eq: "akane" }) {
      childImageSharp {
        fixed(width: 100, height: 100) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    katayori: file(name: { eq: "katayori" }) {
      childImageSharp {
        fixed(width: 100, height: 100) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    takata: file(name: { eq: "takata" }) {
      childImageSharp {
        fixed(width: 100, height: 100) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    hangai: file(name: { eq: "hangai" }) {
      childImageSharp {
        fixed(width: 100, height: 100) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    yasunaga: file(name: { eq: "yasunaga" }) {
      childImageSharp {
        fixed(width: 100, height: 100) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    monta: file(name: { eq: "monta" }) {
      childImageSharp {
        fixed(width: 100, height: 100) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    morita: file(name: { eq: "morita" }) {
      childImageSharp {
        fixed(width: 100, height: 100) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    akane: file(name: { eq: "akane" }) {
      childImageSharp {
        fixed(width: 100, height: 100) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    jokura: file(name: { eq: "jokura" }) {
      childImageSharp {
        fixed(width: 100, height: 100) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    watanabe: file(name: { eq: "watanabe" }) {
      childImageSharp {
        fixed(width: 100, height: 100) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    yoshihara: file(name: { eq: "yoshihara" }) {
      childImageSharp {
        fixed(width: 100, height: 100) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    kayama: file(name: { eq: "kayama" }) {
      childImageSharp {
        fixed(width: 100, height: 100) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    miharun: file(name: { eq: "miharun" }) {
      childImageSharp {
        fixed(width: 100, height: 100) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    onishi: file(name: { eq: "onishi" }) {
      childImageSharp {
        fixed(width: 100, height: 100) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    akie: file(name: { eq: "akie" }) {
      childImageSharp {
        fixed(width: 100, height: 100) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    kaito: file(name: { eq: "kaito" }) {
      childImageSharp {
        fixed(width: 100, height: 100) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    anzai: file(name: { eq: "anzai" }) {
      childImageSharp {
        fixed(width: 100, height: 100) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    ono: file(name: { eq: "ono" }) {
      childImageSharp {
        fixed(width: 100, height: 100) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    nozue: file(name: { eq: "nozue" }) {
      childImageSharp {
        fixed(width: 100, height: 100) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
  }
`
